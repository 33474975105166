<script lang="ts" setup>
const { $directus, $readSingleton } = useNuxtApp()

interface response {
  id: string
  active: boolean
  translations: DirectusTranslation<{
    title: string
    description: string
    cta_link: string
    cta_text: string
  }>
}

const { data } = await useAsyncData<response>
('promo_banner', async () => {
  return await $directus.request($readSingleton('promo_banner', {
    fields: ['*', {
      translations: ['*'],
    }],
  })) as response
})

const mode = useCookie<'minimized' | 'expanded'>('counter', {
  default: () => 'expanded',
})
</script>

<template>
  <ClientOnly>
    <div v-if="data?.active" class="relative z-80">
      <div v-if="mode === 'minimized'" class="w-full border-b border-starline-primary bg-starline-secondary text-neutral-white">
        <Container>
          <div class="flex gap-1rem flex-justify-center flex-items-center py-.4rem">
            <div class="">
              <span class="text-center text-1.6rem font-title">{{ dt(data?.translations)?.title }}</span>
              <button class="mx-2rem inline whitespace-nowrap text-left text-1.2rem underline" @click="mode = 'expanded'">
                {{ $t('promo.more_info') }}
              </button>
            </div>
          </div>
        </Container>
      </div>
      <div v-if="mode === 'expanded'" class="pointer-events-none fixed inset-x-0 bottom-4rem grid place-items-center">
        <Container class="pointer-events-auto flex flex-col gap-1rem border border-neutral-darkGray bg-starline-secondary/80 p-1rem text-neutral-white shadow-starline backdrop-blur">
          <h1 class="text-center text-balance text-2.5rem font-title lg:text-left">
            {{ dt(data?.translations)?.title }}
          </h1>
          <div class="grid grid-cols-1 lg:grid-cols-[1fr_max-content]">
            <p class="min-w- text-center text-balance text-1.6rem leading-2rem lg:text-left">
              {{ dt(data?.translations)?.description }}
            </p>
            <div class="mt-2rem flex flex-row-reverse flex-wrap justify-center gap-2rem lg:mt-0 lg:justify-left">
              <BtnPrimary :link="dt(data?.translations)?.cta_link ?? ''" :text="dt(data?.translations)?.cta_text ?? ''" @click="mode = 'minimized'" />
              <button
                class="inline-block border border-neutral-white bg-transparent px-2.5rem py-1rem text-center text-1.3rem text-neutral-white tracking-4px uppercase transition duration-300 hover:bg-neutral-white hover:text-starline-secondary"
                @click="mode = 'minimized'"
              >
                {{ $t('promo.close') }}
              </button>
            </div>
          </div>
        </Container>
      </div>
    </div>
  </ClientOnly>
</template>

<style>

</style>
