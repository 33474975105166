<script setup lang="ts">
const props = defineProps<{
  index: number
  title: string
  description: string
  image?: string | null
  url: string
}>()
</script>

<template>
  <NuxtLinkLocale :to="props.url" class="group nav-dropdown-item relative block max-w-38rem w-full flex flex-col overflow-hidden border-1px border-white bg-starline-secondary transition duration-300 hover:border-starline-secondary">
    <!-- Background Image -->
    <div class="absolute inset-0 opacity-0 transition duration-300 group-hover:scale-105 group-hover:opacity-100">
      <NuxtImg v-if="props.image" loading="lazy" provider="directus" :src="props.image" :height="300" :width="300" :quality="20" class="size-full object-cover opacity-15" />
      <div v-else class="size-full bg-neutral-black/70" />
    </div>
    <div class="relative flex flex-col p-2rem">
      <!-- Index -->
      <p class="mb-2rem text-2rem text-neutral-white/20 font-title">
        {{ String(props.index + 1).padStart(3, '0') }}
      </p>
      <!-- Title -->
      <p class="mb-0.5rem text-2rem text-neutral-white font-700">
        {{ props.title }}
      </p>
      <!-- Description -->
      <p class="text-1.6rem text-neutral-white">
        {{ props.description }}
      </p>
    </div>
    <!-- Button -->
    <div class="relative ml-2rem mr-auto mt-auto flex items-center gap-0.5rem bg-starline-secondary px-2.5rem py-1rem opacity-0 transition duration-300 group-hover:opacity-100">
      <p class="text-1.3rem text-neutral-white tracking-3px uppercase">
        {{ $t('nav.discover') }}
      </p>
      <IconArrowRight class="h-2rem" />
    </div>
  </NuxtLinkLocale>
</template>

<style scoped>

</style>
