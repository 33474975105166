<script lang="ts" setup>

</script>

<template>
  <div class="min-h-[100dvh] flex flex-col">
    <!-- Spacer -->
    <!-- <div class="h-7rem w-full lg:h-11rem" /> -->
    <!-- Header -->
    <AppHeader />
    <!-- Mobile Header -->
    <AppHeaderMobile />
    <!-- Content -->
    <slot />
    <!-- Footer -->
    <AppFooter class="mt-auto" />
  </div>
</template>

<style>

</style>
