<script setup lang="ts">
const props = defineProps<{
  title: string
  url?: string
  submenuItems?: {
    id: string
    text: string
    link: string
  }[]
}>()

const itemStatus = ref(false)

function toggleItem() {
  if (props.submenuItems && props.submenuItems.length > 0)
    itemStatus.value = !itemStatus.value
}
</script>

<template>
  <div class="relative w-full border-b border-neutral-darkGray py-1.8rem">
    <Container class="w-full">
      <!-- Main item -->
      <div class="flex items-center justify-between" @click="toggleItem">
        <NuxtLinkLocale
          :to="props.url"
          active-class="text-starline-primary"
          class="text-1.8rem text-neutral-white transition duration-300"
          :class="itemStatus ? 'text-starline-primary' : 'font-normal'"
        >
          {{ props.title }}
        </NuxtLinkLocale>
        <IconPlus
          v-if="props.submenuItems?.length"
          class="h-2.4rem w-2.4rem transition duration-300"
          :class="itemStatus ? 'rotate-45deg' : ''"
        />
      </div>
      <!-- Submenu -->
      <div :class="itemStatus ? 'open' : ''" class="accordion-text origin-top transition duration-300">
        <div class="min-h-0 flex flex-col">
          <!-- Spacer -->
          <div class="h-1.6rem min-h-1.8rem w-full" />
          <!-- Submenu Items -->
          <NuxtLinkLocale
            v-for="(item, index) in props.submenuItems"
            :key="index"
            class="py-0.6rem pl-2rem text-1.3rem text-neutral-white tracking-3px uppercase"
            :to="item.link"
          >
            {{ item.text }}
          </NuxtLinkLocale>
        </div>
      </div>
    </Container>
    <!-- Status open sidebar -->
    <div
      class="absolute top-1/2 h-[calc(100%-18px)] w-6px rounded-3px bg-starline-primary transition duration-300 -left-6px -translate-y-1/2"
      :class="itemStatus ? 'opacity-100 translate-x-3px' : 'opacity-0 translate-x-0px'"
    />
  </div>
</template>

<style scoped>
.accordion-text {
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  transition: grid-template-rows 300ms;
}

.accordion-text.open {
  grid-template-rows: 1fr;
}
</style>
