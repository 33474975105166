<script setup lang="ts">
import { breakpointsTailwind } from '@vueuse/core'

const menuStatus = ref(false)

watchEffect(() => {
  if (process.server)
    return
  if (menuStatus.value)
    document.body.style.overflow = 'hidden'
  else
    document.body.style.overflow = ''
})

const nuxtApp = useNuxtApp()

nuxtApp.hook('page:finish', () => {
  menuStatus.value = false
})

const breakpoints = useBreakpoints(breakpointsTailwind)

const isMobile = breakpoints.greater('lg') // only larger than lg

watchEffect(() => {
  if (isMobile.value)
    menuStatus.value = false
})

const { data } = useGlobalConfig()

const navData = computed(() => {
  return data.value?.mobile_nav.map((item) => {
    switch (item.collection) {
      case 'mobile_nav_group':
        return {
          id: item.id,
          text: dt(item.item.translations)?.name,
          items: item.item.items?.map((subItem) => {
            return {
              id: subItem.nav_link_id.id,
              link: subItem.nav_link_id.link,
              text: dt(subItem.nav_link_id.translations)?.text ?? '',
            }
          }),
        }
      case 'nav_link':
      default:
        return {
          id: item.id,
          text: dt(item.item.translations)?.text ?? '',
          link: item.item.link,
        }
    }
  })
})

function toggleMenu() {
  menuStatus.value = !menuStatus.value
}

const route = useRoute()
const isQuotationPage = computed(() => {
  return route.path.split('/').at(-1) === 'quotation'
})
</script>

<template>
  <div>
    <NuxtLinkLocale
      v-if="!isQuotationPage"
      to="/quotation" class="fixed bottom-0 right-2rem z-20 flex items-center gap-1rem rounded-t-xl bg-neutral-black/90 px-2rem py-1rem text-1.3rem text-neutral-white tracking-3px uppercase ring-1 ring-neutral-darkGray backdrop-blur-3xl transition lg:hidden hover:shadow-starline hover:ring-2 hover:ring-starline-primary"
    >
      <div class="i-radix-icons-chat-bubble" />

      {{ $t('nav.quotation') }}
    </NuxtLinkLocale>
    <div class="fixed left-0 top-0 z-50 w-screen lg:hidden">
      <AppPromoBanner />
      <div class="h-70px flex items-center justify-center bg-starline-secondary">
        <!-- Mobile Header -->
        <Container class="relative z-50 w-full flex items-center justify-between">
          <NuxtLinkLocale to="/">
            <LogoStarlineMobile class="max-w-100px w-full" />
          </NuxtLinkLocale>
          <div class="flex flex-col items-end gap-4">
            <div class="flex items-center justify-center gap-0.5rem" @click="toggleMenu">
              <transition name="fade" mode="out-in">
                <p v-if="menuStatus" key="sluiten" class="text-1.3rem text-neutral-darkGray tracking-3px uppercase">
                  {{ $t('nav.close') }}
                </p>
                <p v-else key="menu" class="text-1.3rem text-neutral-darkGray tracking-3px uppercase">
                  {{ $t('nav.menu') }}
                </p>
              </transition>
              <div class="hamburger h-[24px] w-[24px] flex flex-col cursor-pointer items-end justify-center gap-[3px] p-6px">
                <div class="hamburger__bar hamburger__bar1" :class="menuStatus ? 'active' : ''" />
                <div class="hamburger__bar hamburger__bar2" :class="menuStatus ? 'active' : ''" />
                <div class="hamburger__bar hamburger__bar3" :class="menuStatus ? 'active' : ''" />
              </div>
            </div>
          </div>
        </Container>
        <!-- Slideout -->
        <div
          class="mobile-nav absolute left-0 top-0 h-screen w-full flex flex-col justify-between bg-starline-secondary !h-[100dvh]"
          :class="menuStatus ? 'open' : ''"
        >
          <div class="h-6rem w-full flex-none" />

          <div class="w-full flex flex-none justify-end px-12">
            <AppHeaderLanguageSwitcher />
          </div>

          <div class="z-0 w-full flex flex-1 flex-col items-center justify-start overflow-y-scroll">
            <AppHeaderMobileAccordion
              v-for="item in navData"
              :key="item?.id"
              :title="item?.text ?? ''"
              :url="item?.items?.length ? undefined : item?.link"
              :submenu-items="item?.items ?? []"
            />
          </div>
          <!-- Bottom Bar -->
          <div class="max-h-max flex flex-none justify-end">
            <div class="relative z-20 w-full bg-starline-secondary">
              <Container class="h-full w-full flex flex-col items-center justify-center gap-1rem py-4">
                <BtnPrimary
                  class="w-full"
                  :text="$t('nav.configurator')"
                  link="/configurator"
                />
              </Container>
            </div>
          </div>

          <!-- Bottom Bar Gradient -->
          <div class="bottom-bar-gradient absolute bottom-0 -z-10" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.mobile-nav {
  clip-path: circle(1% at 100% 0);
  transition: clip-path 500ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mobile-nav.open {
  clip-path: circle(150% at 100% 0);
}
.menu-height-calc {
  min-height: calc(100dvh - 116px - 90px);
  max-height: calc(100dvh - 116px - 90px);
}
.bottom-bar-gradient {
  background: linear-gradient(to top, #6daadd 0%, transparent 100%);
  @apply absolute z-0 -bottom-30px left-0 w-screen h-200px;
}

/* Hamburger animation */
.hamburger__bar1,
.hamburger__bar3 {
  @apply w-full h-[2px] rounded-2px bg-white;
}

.hamburger__bar2 {
  @apply w-[70%] h-[2px] rounded-2px bg-white;
}

.hamburger__bar1,
.hamburger__bar2,
.hamburger__bar3 {
  transform-origin: center center;
  transition: all 0.4s ease-in-out;
}

.hamburger__bar1.active {
  transform: translateY(5px) rotate(45deg);
}

.hamburger__bar3.active {
  transform: translateY(-5px) rotate(-45deg);
}

.hamburger__bar2.active {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
</style>
