<script setup lang="ts">
const { data } = useGlobalConfig()

const footerData = computed(() => {
  return data.value?.footer_links.map((item) => {
    return {
      id: item.id,
      name: dt(item.footer_link_group_id?.translations)?.name ?? '',
      items: item.footer_link_group_id?.links?.map((subItem) => {
        return {
          id: subItem.footer_link_id.id,
          link: subItem.footer_link_id.link,
          text: dt(subItem.footer_link_id.translations)?.text ?? '',
        }
      }),
    }
  })
})
</script>

<template>
  <BlockBase class="border-t border-neutral-darkGray bg-starline-secondary">
    <!-- <pre>{{ footerData }}</pre> -->
    <Container class="footer-top grid grid-cols-2 my-6rem gap-4rem lg:grid-cols-6">
      <!-- Left Side -->
      <div class="logo col-span-2 lg:col-span-1">
        <NuxtLinkLocale to="/">
          <LogoStarlineWhite />
        </NuxtLinkLocale>
      </div>

      <div v-for="navGroup in footerData" :key="navGroup.id" class="text-neutral-white">
        <!-- Title -->
        <p class="mb-1.7rem font-title">
          {{ navGroup.name }}
        </p>
        <!-- Items -->
        <div class="flex flex-col gap-1rem">
          <NuxtLinkLocale v-for="link in navGroup.items" :key="link.id" :to="link.link">
            {{ link.text }}
          </NuxtLinkLocale>
        </div>
      </div>
      <!-- <div class="reviews flex flex-col items-start gap-1.5rem">
        <NuxtLinkLocale to="/" class="flex items-center border border-neutral-darkGray">
          <div class="border-r border-neutral-darkGray px-1.2rem py-1rem">
            <p class="text-1.1rem tracking-2px">
              4,7
            </p>
          </div>
          <div class="flex items-center gap-0.5rem p-1.1rem">
            <IconStarline />
            <IconStarline />
            <IconStarline />
            <IconStarline />
            <IconStarline />
          </div>
        </NuxtLinkLocale>
        <NuxtLinkLocale to="/" class="text-neutral-white uppercase !tracking-3px">
          Bekijk alle reviews
        </NuxtLinkLocale>
      </div> -->
    </Container>
    <Container class="socials-container mb-5.2rem flex items-center justify-center">
      <div class="socials flex gap-1rem">
        <NuxtLink external target="_blank" to="https://www.facebook.com/StarlineValkenswaard">
          <IconFacebook />
        </NuxtLink>
        <NuxtLink external target="_blank" to="https://www.pinterest.com/starlinepool/">
          <IconPinterest />
        </NuxtLink>
        <NuxtLink external target="_blank" to="https://www.instagram.com/starlinepool/">
          <IconInstagram />
        </NuxtLink>
      </div>
    </Container>
    <Container>
      <div class="footer-bottom flex items-center justify-center gap-2rem border-t border-neutral-darkGray py-2rem">
        <p>
          {{ $t('footer.copyright', {
            year: new Date().getFullYear(),
          }) }}
        </p>
        <NuxtLinkLocale to="/terms-and-conditions">
          {{ $t('footer.terms') }}
        </NuxtLinkLocale>
        <div class="flex items-center">
          <AppHeaderLanguageSwitcher />
        </div>
      </div>
    </Container>
  </BlockBase>
</template>

<style scoped>
/* FOOTER TOP */

@media only screen and (max-width: 1024px) {
  .footer-top {
    text-align: left;
  }

  .socials-container {
    justify-content: start;
  }
}

.footer-top p {
  @apply text-neutral-white text-1.3rem;
}

.footer-top a {
  @apply uppercase text-1.1rem transition tracking-2px duration-200;
}

.footer-top a:hover {
  @apply text-starline-primary;
}

/* SOCIALS */

.socials a {
  @apply h-3.8rem w-3.8rem flex items-center justify-center;
}

.socials a svg {
  @apply text-white transition duration-200;
}

.socials a:hover svg {
  @apply fill-current text-starline-primary;
}

/* FOOTER BOTTOM BAR */
.footer-bottom a,
.footer-bottom p {
  @apply text-neutral-gray text-1.2rem;
}

.footer-bottom a {
  @apply transition duration-200;
}

.footer-bottom a:hover {
  @apply text-starline-primary;
}
</style>
