<script lang="ts" setup>
const { locale, localeCodes } = useI18n()
const switchLocalePath = useSwitchLocalePath()

const currentLocale = computed({
  get: () => {
    return locale.value
  },
  set: (val: string) => {
    navigateTo(switchLocalePath(val))
  },
})
</script>

<template>
  <RadixSelectRoot v-model="currentLocale" class="flex-shrink-0">
    <RadixSelectTrigger class="text-[1.1rem] text-neutral-gray font-400 tracking-3px transition duration-200 hover:text-starline-primary">
      <div class="flex gap-1.5">
        <IconGlobe class="h-1.5rem w-1.5rem" />
        <RadixSelectValue class="inline-block uppercase">
          <span>{{ currentLocale }}</span>
        </RadixSelectValue>
      </div>
    </RadixSelectTrigger>
    <!-- <RadixSelectPortal> -->
    <RadixSelectContent position="popper" side="bottom" align="end" class="z-100 bg-starline-secondary px-0.5rem py-0.8rem text-[1.1rem] text-white">
      <RadixSelectViewport class="flex flex-col items-center gap-.7rem">
        <RadixSelectItem
          v-for="availableLocale, index in localeCodes"
          :key="index"
          :value="availableLocale"
          :disabled="availableLocale === currentLocale"
          class="cursor-pointer select-none border-px border-neutral-darkGray px-1.6rem py-0.8rem data-[disabled]:pointer-events-none data-[disabled]:border-3px data-[disabled]:border-starline-primary data-[disabled]:shadow-starline data-[highlighted]:border-starline-primary data-[highlighted]:outline-none"
        >
          <RadixSelectItemText class="uppercase">
            {{ availableLocale }}
          </RadixSelectItemText>
        </RadixSelectItem>
      </RadixSelectViewport>
    </RadixSelectContent>
    <!-- </RadixSelectPortal> -->
  </RadixSelectRoot>
</template>

<style scoped>
</style>
